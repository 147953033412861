import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

function App() {
  // ********************************
  // 1) Dynamically inject Tailwind
  // ********************************
  useEffect(() => {
    // Create a <link> element
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.tailwindcss.com';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  /**************************************************/
  /*           IN-MEMORY PATIENTS & OUTCOMES        */
  /**************************************************/
  const [patients, setPatients] = useState([
    {
      id: 1,
      name: 'Alice Smith',
      condition: 'Hypertension',
      contacted: false,
      contactLogs: [],
    },
    {
      id: 2,
      name: 'Bob Johnson',
      condition: 'Diabetes',
      contacted: false,
      contactLogs: [],
    },
    {
      id: 3,
      name: 'Carol Danvers',
      condition: 'Asthma',
      contacted: true,
      contactLogs: [{ time: new Date().toLocaleString(), outcome: 'No answer' }],
    },
  ]);

  const outcomes = [
    'No answer',
    'Booked appointment',
    'Call back later please',
    'Not interested',
    'Do not contact',
  ];
  const [selectedOutcomes, setSelectedOutcomes] = useState({});

  /**************************************************/
  /*        GLOBAL AGENT STATS & CALL LOGGING       */
  /**************************************************/
  const [callsMade, setCallsMade] = useState(0);
  const [callsAnswered, setCallsAnswered] = useState(0);
  const [appointmentsBooked, setAppointmentsBooked] = useState(0);

  // A global log for each call made by the "agent"
  const [callsLog, setCallsLog] = useState([]);

  // Agent running states
  const [agentRunning, setAgentRunning] = useState(false);
  const [agentInterval, setAgentInterval] = useState(null);

  // Start the automated agent
  const handleStartAgent = () => {
    if (agentRunning) return;
    setAgentRunning(true);

    const interval = setInterval(() => {
      // Each interval represents an outgoing call
      setCallsMade((prevMade) => {
        const newCallsMade = prevMade + 1;

        const answered = Math.random() < 0.5;
        let booked = false;

        if (answered) {
          setCallsAnswered((prevAns) => prevAns + 1);
          if (Math.random() < 0.3) {
            booked = true;
            setAppointmentsBooked((prevBooked) => prevBooked + 1);
          }
        }

        // Record in the global callsLog with a unique callNumber
        setCallsLog((prevLog) => [
          ...prevLog,
          {
            time: new Date().toLocaleString(),
            callNumber: newCallsMade,
            answered,
            booked,
          },
        ]);

        return newCallsMade;
      });
    }, 5000);

    setAgentInterval(interval);
  };

  // Stop the automated agent
  const handleStopAgent = () => {
    if (agentInterval) {
      clearInterval(agentInterval);
      setAgentInterval(null);
    }
    setAgentRunning(false);
  };

  // Toggle "Contacted" status for a patient
  const handleContactToggle = (patientId) => {
    setPatients((prev) =>
      prev.map((p) =>
        p.id === patientId ? { ...p, contacted: !p.contacted } : p
      )
    );
  };

  // Handle outcome selection
  const handleOutcomeChange = (patientId, outcome) => {
    setSelectedOutcomes((prev) => ({
      ...prev,
      [patientId]: outcome,
    }));
  };

  // Record the outcome in the patient's log
  const handleRecordOutcome = (patientId) => {
    const chosenOutcome = selectedOutcomes[patientId];
    if (!chosenOutcome) return;

    const newLogEntry = {
      time: new Date().toLocaleString(),
      outcome: chosenOutcome,
    };

    setPatients((prev) =>
      prev.map((p) => {
        if (p.id === patientId) {
          return {
            ...p,
            contactLogs: [...p.contactLogs, newLogEntry],
          };
        }
        return p;
      })
    );

    // Reset the dropdown for that patient
    setSelectedOutcomes((prev) => ({
      ...prev,
      [patientId]: '',
    }));

    // If outcome is "Booked appointment," increment these stats
    // but do NOT increment callsMade or re-log a call
    if (chosenOutcome === 'Booked appointment') {
      setAppointmentsBooked((prev) => prev + 1);
      setCallsAnswered((prev) => prev + 1);
    }
  };

  /**************************************************/
  /*                   CHART DATA                   */
  /**************************************************/
  const chartData = {
    labels: ['Calls Made', 'Calls Answered', 'Appointments Booked'],
    datasets: [
      {
        label: 'Agent Stats',
        backgroundColor: '#60A5FA',
        data: [callsMade, callsAnswered, appointmentsBooked],
      },
    ],
  };

  // Chart configuration
  const chartOptions = {
    legend: { display: false },
    responsive: true,
    maintainAspectRatio: false, // Let's fill the container
    scales: {
      yAxes: [
        {
          ticks: { beginAtZero: true },
        },
      ],
    },
  };

  return (
    <>
      {/* Spiffy custom scrollbar for the call log */}
      <style>
        {`
          .call-log-custom {
            scrollbar-width: thin;
            scrollbar-color: #93c5fd #f3f4f6;
          }
          .call-log-custom::-webkit-scrollbar {
            width: 8px;
          }
          .call-log-custom::-webkit-scrollbar-track {
            background: #f3f4f6;
            border-radius: 9999px;
          }
          .call-log-custom::-webkit-scrollbar-thumb {
            background: #93c5fd;
            border-radius: 9999px;
            border: 2px solid #f3f4f6;
          }
        `}
      </style>

      <div className="min-h-screen bg-gradient-to-tr from-blue-100 via-blue-50 to-blue-100 p-8">
        <div
          className="max-w-2xl mx-auto bg-white shadow-2xl rounded-xl p-6 space-y-8 transition transform"
          style={{ paddingBottom: '64px' }}
        >
          <h1
            className="
              text-4xl 
              font-extrabold
              text-center
              bg-clip-text
              text-transparent
              bg-gradient-to-r
              from-blue-500
              to-purple-600
              tracking-wide
            "
          >
            Healthcare Demo
          </h1>

          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:justify-center gap-4">
              <button
                onClick={handleStartAgent}
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 hover:shadow-lg transition"
              >
                Start Agent
              </button>
              <button
                onClick={handleStopAgent}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 hover:shadow-lg transition"
              >
                Stop Agent
              </button>
            </div>

            <p
              className={`text-center font-medium text-sm ${
                agentRunning ? 'text-green-700' : 'text-red-700'
              }`}
            >
              {agentRunning
                ? 'Agent is running automatically making calls...'
                : 'Agent is stopped.'}
            </p>

            <div className="bg-blue-50 p-4 rounded-md border border-blue-200">
              <h2 className="text-lg font-semibold text-blue-700">
                Recommended next action for agent:
              </h2>
              <p className="text-sm mt-1 text-gray-700">
                This is mock data. The agent should call any patient who hasn’t
                been contacted or requested a callback. Then record the outcome
                below.
              </p>
            </div>
          </div>

          {/* Ensure the container is at least 300px tall */}
          <div
            className="bg-blue-50 p-4 rounded-md border border-blue-200"
            style={{ minHeight: '300px' }}
          >
            <h3 className="text-md font-semibold text-blue-700 mb-2">
              Agent Performance
            </h3>
            {/* And ensure the chart itself is also at least 300px tall */}
            <div className="h-full min-h-[300px]">
              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>

          <div className="bg-blue-50 p-4 rounded-md border border-blue-200">
            <h3 className="text-md font-semibold text-blue-700 mb-2">
              Global Agent Call Log
            </h3>
            <div className="max-h-48 overflow-y-auto pr-2 call-log-custom">
              <ul className="list-disc list-inside text-sm space-y-1">
                {callsLog.map((log, index) => {
                  const answeredTxt = log.answered ? 'answered' : 'no answer';
                  const bookedTxt = log.booked ? ' - appointment booked' : '';
                  return (
                    <li key={index} className="text-gray-800">
                      <span className="font-semibold text-blue-600">
                        [{log.time}]
                      </span>{' '}
                      Call #{log.callNumber}: {answeredTxt}
                      {bookedTxt}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <ul className="space-y-4">
            {patients.map((patient) => (
              <li
                key={patient.id}
                className="border border-gray-200 rounded-md p-4 flex flex-col md:flex-row md:items-center md:justify-between hover:shadow-lg transition"
              >
                <div>
                  <p className="text-lg font-semibold text-gray-800">
                    {patient.name}{' '}
                    <span className="text-sm text-gray-500">
                      ({patient.condition})
                    </span>
                  </p>
                  <p className="text-sm mt-1">
                    <span className="font-semibold">Status:</span>{' '}
                    <em
                      className={
                        patient.contacted ? 'text-green-600' : 'text-red-600'
                      }
                    >
                      {patient.contacted ? 'Contacted' : 'Not Contacted'}
                    </em>
                  </p>

                  {patient.contactLogs.length > 0 && (
                    <div className="mt-2 text-sm">
                      <strong className="text-gray-700">Contact Logs:</strong>
                      <ul className="list-disc list-inside ml-4 mt-1 space-y-1">
                        {patient.contactLogs.map((clog, i) => (
                          <li key={i} className="text-gray-600">
                            {clog.time} – {clog.outcome}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="mt-3 md:mt-0 flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2">
                  <button
                    onClick={() => handleContactToggle(patient.id)}
                    className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 hover:shadow-lg transition"
                  >
                    {patient.contacted ? 'Unmark' : 'Mark'} Contacted
                  </button>

                  <select
                    className="border border-gray-300 rounded-md px-2 py-1"
                    value={selectedOutcomes[patient.id] || ''}
                    onChange={(e) =>
                      handleOutcomeChange(patient.id, e.target.value)
                    }
                  >
                    <option value="">-- Outcome --</option>
                    {outcomes.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={() => handleRecordOutcome(patient.id)}
                    className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 hover:shadow-lg transition"
                  >
                    Record Outcome
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default App;
